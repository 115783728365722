
import React, { useState, useEffect } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // dependent on utc plugin
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { AppBar, CssBaseline, Toolbar, Typography, Container, Link, CircularProgress, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';

import { ReactComponent as UscssLogo } from './assets/SafeSportLogo_PrimaryLogo.svg';
import { ReactComponent as FilterIcon } from './assets/filter_alt-black-18dp.svg';
import IntroText from './IntroText.js';
import ReactGA from 'react-ga';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-LGY928V1DM',
        {
            debug: false
        });
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const api_host = process.env.REACT_APP_API_HOST;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://uscenterforsafesport.org/" target="_blank" rel="noopener">
         U.S. Center for SafeSport
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    margin: theme.spacing(0, 3),
  },
  toolbar: {
    flexWrap: 'wrap',
    color: '#283B5A',

  },
  toolbarTitle: {
    flexGrow: 1,
  },
  toolbarLabel: {
    margin: theme.spacing(1, 1.5),
    color: '#283B5A'
  },
  link: {
    margin: theme.spacing(),
    color: '#283B5A'
  },
  heroContent: {
    backgroundColor: '#283B5A',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(),
  },
}));

const App = () => {
  const classes = useStyles();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(advancedFormat);

  const [rowData, setRowData] = useState([]);
  const [affiliationsData, setAffiliationsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastModified, setLastModified] = useState(null);

  useEffect(() => {
      fetch(
        // 'testdata.json'
          'https://' + api_host + '/api/v1/published_case',
        )
        .then(result => {
          return result.json();
        })
        .then(rowData => {
          if (!('lastModified' in rowData) || !('data' in rowData)) {
              // todo add processing for this case
              return
          }
          const allAffiliations = {};
          setLastModified(Date.parse(rowData['lastModified']+'Z'))
          const rowDataMapped = rowData['data'].map(r => {
              // Concat respondent_ngb_affiliation(s) into one field
              let respondent_ngb_affiliation = r.respondent_ngb_affiliation;
              const additional = r.respondent_additional_ngb_affiliations;
              if(additional) {
                const temp_affiliations = [respondent_ngb_affiliation, ...additional];
                respondent_ngb_affiliation = temp_affiliations.join(', ');
                //Gather up the affiliations to keys for later single entry filtering
                temp_affiliations.forEach(aff => {
                  allAffiliations[aff] = "found";

                })
              } else if(respondent_ngb_affiliation){
                allAffiliations[respondent_ngb_affiliation] = "found";
              }

              // additional_dispositions
              const additional_dispositions = r.additional_dispositions ? r.additional_dispositions.join(', ') : "";
             return {...r, respondent_ngb_affiliation, additional_dispositions};
          });
          setRowData(rowDataMapped);
          setIsLoading(false);
          const affiliationKeys = Object.keys(allAffiliations);
          setAffiliationsData(affiliationKeys);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.error(error);
          setIsLoading(false);
        }
      );


  }, []);

  const misconductSplitter = (value => {
    const misconductArray = value ? String(value).split(',') : [];
    const misconductWithBreaks = misconductArray.join('; ');
    return misconductWithBreaks;
  });

  const dateFormatter = (value => {
    const formattedDate = value ? dayjs(value).utc().format('MM/DD/YYYY') : "";
    // const formattedDate = value ? dayjs(value).utc().format('MM/DD/YYYY') : "";
    return formattedDate;
  });

  const dateComparator = ((d1, d2) => {
    const date1 = dayjs(d1);
    const date2 = dayjs(d2);

    if (d1 === null && d2 === null) {
      return 0;
    }
    if (d1 === null) {
      return -1;
    }
    if (d2 === null) {
      return 1;
    }
    const dateDiff = date1.subtract(date2);
    return dateDiff;
  });

  const nowDate = (() => {
    if (!lastModified) {
        return ''
    }
    return dayjs(lastModified).utc().local().format('MM/DD/YYYY hh:mm:ss z');
  });

  const columns = [
    {
     name: "name",
     label: "Name",
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "city",
     label: "City",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "respondent_state",
     label: "State",
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "respondent_ngb_affiliation",
      label: "Sport Affiliation(s)",
      options: {
        filter: true,
        filterOptions: {
          logic: (location, filters, row) => {
            const found = location && filters.filter(f => {
              return location.includes(f);
            });
            const isFound = found && Array.isArray(found) && found.length > 0 ? found.every(v => v && true) : false;
            return !isFound;

          },
          names: affiliationsData,
        }
      }
    },
    {
      name: "published_misconduct",
      label: "Misconduct",
      options: {
       filter: false,
       sort: true,
       customBodyRender: value => {
        return misconductSplitter(value);
      },
      }
     },
     {
      name: "disposition",
      label: "Action Taken",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "additional_dispositions",
      label: "Additional Detail",
      options: {
       filter: false,
       sort: true,
      }
     },
     {
      name: "date_of_issuance",
      label: "Date of Issuance",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => {
          return dateFormatter(value);
        },
        sortCompare: ((order) => {
          return (obj1, obj2) => {
            const val1 = obj1.data;
            const val2 = obj2.data;
            const modifier = (order === 'asc' ? 1 : -1);
            return dateComparator(val1, val2) * modifier;
          }
        })
      }
     },

     {
      name: "adjudicating_body",
      label: "Adjudicating Body",
      options: {
       filter: true,
       sort: true,
      }
     },
   ];

   const options = {
    selectableRows: 'none',
    print: false,
    download: false,
    searchOpen: true,
    searchPlaceholder: '(e.g First or Last Name or City or Sport)',
    viewColumns: false,
    sortOrder: {
      name: 'date_of_issuance',
      direction: 'desc'
    }
  };

  const components = {
    icons: {
      FilterIcon,
    }
  };

  return (
    <React.Fragment>
    <CssBaseline />
    <AppBar position="relative" color="default">
      <Toolbar className={classes.toolbar}>
        <Link variant="button" color="textPrimary" href="https://uscenterforsafesport.org" target="_blank"
          rel="noopener"
          className={classes.link}>
          <UscssLogo width="200" className={classes.logo}/>
        </Link>
        <Typography variant="h6" noWrap className={classes.toolbarTitle}>
          Centralized Disciplinary Database
        </Typography>
        <nav>
          <Typography variant="subtitle2" className={classes.toolbarLabel}>
              Last Updated: {nowDate()}
          </Typography>
        </nav>

      </Toolbar>
    </AppBar>
    <main>
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth="xl">
          <IntroText/>

          <div>
            {isLoading ? (
              <div>
                <Paper>
                  <CircularProgress style={{ position: 'relative', top: '50%', left: '50%'}}/>
                </Paper>
              </div>
            ) : (
              <MUIDataTable
                data={rowData}
                columns={columns}
                options={options}
                components={components}
              />
            )
            }
          </div>
        </Container>
      </div>
    </main>
    {/* Footer */}
    <footer className={classes.footer}>
      <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
      </Typography>
      <Copyright />
    </footer>
    {/* End footer */}
  </React.Fragment>
  );
};

export default App;

