import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    paragraphText: {
      padding: theme.spacing(0, 0, 2, 0),
    },
}));

const IntroText = (props) => {
    const classes = useStyles();
    const PARA_VARIANT = "body2";

    return(
        <div>
            <Card >
                <CardContent>
                <Typography component="p" variant={PARA_VARIANT} className={classes.paragraphText}>
                    The U.S. Center for SafeSport’s Centralized Disciplinary Database is a resource designed to
                    keep the public informed when individuals connected with the U.S. Olympic & Paralympic Movements
                    are either subject to certain temporary restrictions pending investigation by the Center or are
                    subject to certain sanctions after an investigation found them in violation of
                    the <Link href="https://uscenterforsafesport.org/response-and-resolution/safesport-code/" target="_blank">SafeSport Code</Link>.
                    The database also contains certain eligibility decisions made by the National Governing Bodies (NGB),
                    their Local Affiliated Organizations (LAO), or the U.S. Olympic & Paralympic Committee (USOPC),
                    including those rendered prior to the establishment of the Center.
                </Typography>
                <Typography component="p" variant={PARA_VARIANT} className={classes.paragraphText}>
                    Users can search the database by Name, City, State, and/or Sport Affiliation(s). Enter as much
                    (or as little) information as you know. Search results will include the Participant’s Name, City,
                    State, Sport Affiliation(s), Decision Date, Misconduct, and Action Taken.
                </Typography>
                <Typography component="p" variant={PARA_VARIANT} className={classes.paragraphText}>
                    What if you recognize a name in the CDD? What if you search for a name and they are not on the
                    list? The CDD can bring up many feelings. <Link href="https://uscenterforsafesport.org/cdd-guide" target="_blank">Here are some tips and resources to help.</Link>
                </Typography>
                <Typography component="p" variant={PARA_VARIANT} className={classes.paragraphText}>
                    <Link href="https://uscenterforsafesport.org/wp-content/uploads/2019/12/CDD-Link-1-overview-and-definitions-v2.pdf"
                        target="_blank">Learn more</Link> about
                    the types of records published and the information included within the Centralized Disciplinary Database.
                </Typography>
                <Typography component="p" variant={PARA_VARIANT} className={classes.paragraphText}>
                    <Link href="https://uscenterforsafesport.org/wp-content/uploads/2020/06/CDD-Link-2-NGB-LAO-USOPC-mattersv5.pdf"
                        target="_blank">Read about</Link> the
                    types of misconduct handled by the NGBs, LAOs, and USOPC, and what’s included in the Centralized Disciplinary Database.
                </Typography>
                <Typography component="p" variant={PARA_VARIANT} className={classes.paragraphText}>
                    IMPORTANT: By accessing and using the Centralized Disciplinary Database,
                    you agree to accept the U.S. Center for
                    SafeSport’s <Link href="https://uscenterforsafesport.org/terms-of-use/"
                        target="_blank">Terms and Conditions</Link>.

                </Typography>

                </CardContent>
            </Card>
        </div>
    )
}
export default IntroText



